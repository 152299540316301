
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 580px;
  height: 360px;
  margin: 0 20px;
  background-color: $tickets-bundle-card-bg-default;
  transition: $card-bg-transition;

  @include breakpoint($sm) {
    left: 0;
    right: 0;
    margin: 0 auto;
    flex-direction: column;
    width: 335px;
    height: 690px;
  }

  &:hover {
    cursor: pointer;
    background-color: $tickets-bundle-card-bg-hover;
  }
}

.info {
  position: relative;
  padding: 60px 40px 0;
  width: 290px;
  border: 1px solid $tickets-bundle-card-border;

  @include breakpoint($sm) {
    width: 335px;
    height: 280px;
  }
}

.infoHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include h5;

  color: $black;
  margin-bottom: 14px;
}

.description {
  @include p3;

  color: $black;

  @include breakpoint($sm) {
    display: none;
  }
}

.age {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid $black-40;
  border-radius: 50%;
  padding: 2px;

  @include t6;

  color: $black-40;
  text-align: center;
  margin-left: 40px;
}

.expire {
  @include p3;

  color: $black-60;
  margin-bottom: 10px;
}

.priceWrapper {
  position: absolute;
  bottom: 30px;
  left: 40px;
}

.priceDescription {
  @include t4;

  color: $black-60;
  margin-bottom: 5px;
}

.priceInfo {
  display: flex;
  align-items: center;

  @include t3;

  color: $black;

  svg {
    margin-right: 10px;

    path {
      fill: $black;
    }
  }
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;
  margin: 20px;

  @include t5;

  text-transform: uppercase;
  background-color: $black-5;
  color: $black;
}

.image {
  flex-grow: 1;

  @include breakpoint($sm) {
    height: 410px;
  }
}
