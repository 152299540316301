
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  width: 270px;
  height: 360px;
  background-color: $card-main-bg-default;
  transition: $card-bg-transition;

  &:hover {
    background-color: $card-main-bg-hover;
  }
}

.info {
  border: 1px solid $card-main-border;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 19px 20px;
  width: 270px;
  height: 119px;
}

.title {
  @include cardMain;

  color: $black;
  overflow: hidden;
}

.text {
  @include t4;

  color: $black-60;
  overflow: hidden;
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
}

.image {
  height: 241px;
}
