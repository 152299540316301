
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 335px;
  height: 220px;
  overflow: hidden;
  background-color: $white;

  @include breakpoint($xl) {
    width: 580px;
    height: 180px;
  }
}

.icon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  grid-template-areas: 'block1 block2'
    'block3 block4';

  @include breakpoint($md-and-sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0;
    grid-template-areas: 'block1 block1'
      'block2 block3'
      'block4 block5'
      'block6 block7';
  }
}

.block1,
.block2,
.block3,
.block4, {
  width: 90px;
  height: 90px;

  img {
    width: 90px;
    height: 90px;
  }
}

.block2,
.block3,
.block4,
.block5,
.block6,
.block7 {
  @include breakpoint($md-and-sm) {
    width: 45px;
    height: 45px;

    img {
      width: 45px;
      height: 45px;
    }
  }
}

.block1 {
  grid-area: block1;
}

.block2 {
  grid-area: block2;
}

.block3 {
  grid-area: block3;
}

.block4 {
  grid-area: block4;
}

.block5 {
  grid-area: block5;
}

.block6 {
  grid-area: block6;
}

.block7 {
  grid-area: block7;
}

.info {
  position: relative;
  padding-top: 20px;
  padding-left: 30px;
  width: 100%;
  border: 1px solid $border;
}

.title {
  @if mixin-exists(accent3) {
    @include accent3;
  }

  color: $black;
  text-transform: uppercase;

  @include breakpoint($md-and-sm) {
    @if mixin-exists(accent4) {
      @include accent4;
    }

    line-height: 24px;
  }
}

.group {
  @include t6;

  position: absolute;
  bottom: 23px;
  left: 30px;
  color: $black-60;
  margin-top: 2px;

  @include breakpoint($md-and-sm) {
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 6px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: $button-bg-default;
  width: 185px;
  color: $button-text-action-default;
  padding: 7px 0;

  @include b3;

  text-transform: uppercase;
  margin-top: 38px;

  @include breakpoint($xl) {
    position: absolute;
    bottom: 23px;
    right: 40px;
    margin-top: 0;
  }

  &:hover {
    background-color: $button-bg-hover;
  }
}
