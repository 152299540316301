
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.image {
  background: linear-gradient(
    to top,
    rgb(0 0 0 / 44%),
    rgb(0 0 0 / 48%) 50%,
    rgb(0 0 0 / 0%)
  );

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
}
