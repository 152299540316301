
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.main {
  display: flex;
  flex-direction: column;
}

.section {
  position: relative;
  margin-bottom: 40px;

  &:first-child {
    margin-top: 80px;
  }

  @include breakpoint($md) {
    margin-bottom: 80px;
    padding: 0;
  }

  @include breakpoint($xl) {
    margin-bottom: 80px;
    padding: 0;
  }

  @include breakpoint($sm) {

    &:first-child {
      margin-top: 40px;
    }

    &:last-child {
      margin-bottom: 80px;
    }
  }
}

.banner {
  margin-bottom: 80px;

  @include breakpoint($md-and-sm) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @include breakpoint($sm) {
    margin-bottom: 40px;
  }
}
