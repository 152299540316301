
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  width: 270px;
  height: 360px;

  &:hover {

    > .info {
      background-color: $card-main-bg-hover;
      position: absolute;
      bottom: 0;
      height: auto;
      min-height: 119px;
    }
  }
}

.mediumType {
  width: 580px;
  height: 360px;
}

.largeType {
  width: 1200px;
  height: 360px;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 19px 20px;
  width: 270px;
  height: 119px;
  background-color: $card-main-bg-default;
  border: 1px solid $card-main-border;
  transition: $card-bg-transition;
}

.title {
  @include cardMain;

  color: $black;
  overflow: hidden;
}

.price {
  @include t4;

  color: $black-60;
  display: flex;
  flex: 1 1;
  align-items: flex-end;
  margin-top: 6px;

  svg {
    margin-right: 10px;
    margin-bottom: 2px;

    path {
      fill: $black-60;
    }
  }
}

.mediumTypeInfo,
.largeTypeInfo {
  width: 100%;
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 20px;
  padding: 6px 13px;

  @include t5;

  text-transform: uppercase;
  text-align: center;
  background-color: $white-80;
  color: $black;
}

.image {
  height: 241px;
  overflow: hidden;
}
