
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  border: 1px solid $border;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 335px;
  height: 220px;

  @include breakpoint($xl) {
    width: 580px;
    height: 180px;
  }
}

.icon {
  width: 160px;
  height: 100%;
  flex-shrink: 0;
  background-color: $social-media-icon-bg;

  svg path {
    fill: $social-media-icon-color;
  }

  @include breakpoint($md-and-sm) {
    width: 100px;
  }
}

.info {
  position: relative;
  padding-top: 23px;
  padding-left: 20px;
  width: 100%;
  background-color: $white;

  @include breakpoint($md-and-sm) {
    padding-top: 40px;
    padding-left: 10px;
  }
}

.title {
  @include cardSMTextDesktop;

  @include breakpoint($md-and-sm) {
    @include cardSMTextMobile;
  }
}

.group {
  @include t1;

  margin-top: 2px;

  @include breakpoint($md-and-sm) {
    @include p3;

    margin-top: 6px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $button-bg-default;
  width: 180px;
  height: 40px;
  color: $button-text-action-default;
  padding: 7px 0;

  @include b3;

  text-transform: uppercase;
  margin-top: 26px;

  @include breakpoint($xl) {
    position: absolute;
    bottom: 23px;
    right: 40px;
    margin-top: 0;
  }

  &:hover {
    background-color: $button-bg-hover;
  }
}
