
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 580px;
  height: 360px;
  margin: 0 20px;
  background-color: $news-main-card-bg-default;
  transition: $card-bg-transition;

  @include breakpoint($sm) {
    left: 0;
    right: 0;
    margin: 0 auto;
    flex-direction: column-reverse;
    width: 335px;
    height: 690px;
  }

  &:hover {
    background-color: $news-main-card-bg-hover;
  }
}

.info {
  position: relative;
  padding: 60px 40px 0;
  width: 290px;
  border: 1px solid $news-main-card-border;

  @include breakpoint($sm) {
    width: 335px;
    height: 280px;
  }
}

.title {
  @include h5;

  color: $news-main-card-text;
  margin-bottom: 14px;
}

.description {
  @include p3;

  color: $news-main-card-text;
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 20px;
  padding: 6px 13px;

  @include t5;

  height: 30px;
  text-transform: uppercase;
  background-color: $news-main-badge-bg;
  color: $news-main-card-text;
}

.image {
  flex-grow: 1;

  @include breakpoint($sm) {
    height: 410px;
  }
}
