
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  width: 580px;
  height: 360px;
  display: flex;
  color: $tour-main-card-text;

  @include breakpoint($sm) {
    width: 335px;
    height: 690px;
    flex-direction: column;
    margin: 0 auto;
  }

  &:hover {

    > .info {
      background-color: $tour-main-card-bg-hover;
    }
  }

  &.catalog {
    @include breakpoint($md) {
      width: 335px;
      height: 690px;
      flex-direction: column;
    }

    .image {
      @include breakpoint($md) {
        width: 100%;
        height: 410px;
      }
    }

    .info {
      @include breakpoint($md) {
        width: 100%;
      }
    }
  }
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: $card-bg-transition;
  padding: 60px 40px 30px;
  width: 50%;
  height: 100%;
  border: 1px solid $tour-main-card-border;
  background-color: $tour-main-card-bg-default;

  @include breakpoint($sm) {
    width: 100%;
  }
}

.duration {
  @include t3;

  color: rgba($tour-main-card-text, 0.7);
}

.title {
  margin-top: 5px;

  @include h5;

  color: $tour-main-card-text;
  overflow: hidden;
}

.description {
  margin-top: 14px;

  @include p3;

  color: $tour-main-card-text;
  overflow: hidden;

  @include breakpoint($md-and-sm) {
    display: none !important;
  }
}

.price {
  @include t3;

  color: $tour-main-card-text;
  display: flex;
  align-items: center;
  padding-top: 6px;
  margin-top: auto;
  margin-bottom: -2px;

  svg {
    margin-right: 10px;
    margin-bottom: 2px;

    path {
      fill: $tour-main-card-text;
    }
  }
}

.badge {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;

  @include t5;

  text-transform: uppercase;
  text-align: center;
  background-color: $tour-main-badge-bg;
  color: $tour-main-card-text;
}

.image {
  height: 100%;
  width: 50%;
  flex-shrink: 0;

  @include breakpoint($sm) {
    width: 100%;
    height: 410px;
  }

  img {
    height: 100%;
  }
}
