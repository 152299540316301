
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  overflow: hidden;

  @include breakpoint($xl-and-md) {
    padding-bottom: 70px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;

  @include breakpoint($sm) {
    margin-bottom: 20px;
  }
}

.title {
  @include recBlockTitle;

  opacity: 0.8;

  @include breakpoint($sm) {
    @include recBlockTitleMobile;
  }
}

.button {
  @include b1;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px solid $excursion-main-button-bg-default;
  background-color: $excursion-main-button-bg-default;
  color: $excursion-main-button-text-default;
  text-transform: uppercase;
  padding: 6px 13px 6px 19px;
  transition: all 0.3s ease;

  @include breakpoint($sm) {
    padding-right: 9px;
    padding-left: 15px;
  }

  svg {
    margin-left: 14px;

    path {
      fill: $excursion-main-button-text-default;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    background-color: $white;
    color: $excursion-main-button-hover;
    border: 1px solid $excursion-main-button-hover;

    svg {

      path {
        fill: $excursion-main-button-hover;
      }
    }
  }
}

.buttonText {
  @include breakpoint($sm) {
    overflow: hidden;
    white-space: nowrap;
    width: 30px;
  }
}

.cards {
  width: 1240px;

  @include breakpoint($md-and-sm) {
    width: calc(100% + 40px);
  }
}

.cardWrapper {
  margin: 0 20px;

  @include breakpoint($md-and-sm) {
    margin: 0;
  }
}
